<template>
  <router-view></router-view>
</template>

<script>
import store from "@/store";
import {diagLog} from "@/core/helpers/GlobalHelper";

export default {
  name: "BankLayout",
  mounted() {
    setTimeout(() => {
      diagLog('BANK => ', this.bank);
      diagLog('USER => ', this.currentUser);
    }, 1000)
  },
  computed: {
    bank() {
      return store.getters.bank;
    },
    currentUser() {
      return store.getters.currentUser;
    }
  }
}
</script>
